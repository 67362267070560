/*
 * @Author: showtan
 * @Date: 2024-07-02 09:32:48
 * @Version: 1.0
 * @LastEditTime: 2024-07-02 09:41:44
 * @LastEditors: showtan
 * @Description: 加解密
 * @FilePath: /japan-h5/src/utils/encrypt.js
 */
import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt";
import * as config from "../config";

/**
 * MD5加密
 * @param values 加密字符串
 * @returns string
 */
export const md5 = (values) => {
	return CryptoJS.MD5(values).toString();
};

/**
 * AES加密
 * @param values 加密字符串
 * @returns string
 */
export const aesEncrypt = (values) => {
	return CryptoJS.AES.encrypt(values, config.aesKey).toString();
};

/**
 * AES解密
 * @param values 解密字符串
 * @returns string
 */
export const aesDecrypt = (values) => {
	const bytes = CryptoJS.AES.decrypt(values, config.aesKey);
	return bytes.toString(CryptoJS.enc.Utf8);
};

/**
 * RSA加密
 * @param values 加密字符串
 * @returns string
 */
export const rsaEncrypt = (values) => {
	const encryptor = new JSEncrypt();
	encryptor.setPublicKey(config.rsaCert.publicKey); // 设置公钥
	return encryptor.encrypt(values || "") || "";
};

/**
 * RSA解密
 * @param values 解密字符串
 * @returns string
 */
export const rsaDecrypt = (values) => {
	const decryptor = new JSEncrypt();
	decryptor.setPrivateKey(config.rsaCert.privateKey); // 设置秘钥
	return decryptor.decrypt(values);
};
