/*
 * @Author: showtan
 * @Date: 2024-07-02 16:46:43
 * @Version: 1.0
 * @LastEditTime: 2024-09-05 09:31:22
 * @LastEditors: showtan
 * @Description: ListItem
 * @FilePath: /japan-h5/src/components/ListItem.js
 */
import { memo } from "react";
import { Image } from "antd-mobile";
import moment from "moment";
import mercari from "../assets/mercari.png";
import buy from "../assets/buy.png";
import shop from "../assets/shop.png";

/**
 * 列表项
 * @param {object} param0 status: 1待入库 2入库后拍照 3合箱待审 4出库待审
 * @returns
 */
function ListItem({ item, status, detailHandler }) {
	const time = status === 4 ? item.payment?.finished_time : status === 2 ? item.in_time : item.apply_merge_box_time;
	const list = [];
	if (status === 3) {
		// time.is_task_photo && list.push("入库后拍照");
		item.is_firm && list.push("外包装箱加固");
		item.is_bubble_film && list.push("气泡膜加固");
	}

	return (
		<div className="list-item" onClick={detailHandler}>
			<div className="list-item-content">
				{/* 订单编号、用户ID */}
				<div className="info-text">订单编号：{item.order_no}</div>
				<div className="info-text">用户ID：{item.user_id + "/" + item.user?.account}</div>

				{/* 包裹编号 */}
				<div className="info-text info-text-m">
					包裹编号：<span>{item.delivery_identifier}</span>
				</div>
				{status === 1 ? (
					// 待入库
					<div className="row-center">
						{/* 商品缩略图 */}
						<Image lazy src={(item.goods ? item.goods.goods_photos?.[0] : item.purchasing ? item.purchasing?.goods_photos : "") || "/404"} fit="cover" className="image" />
						<div className="col justify-between item-goods">
							{/* 商品名称 */}
							<div className="ellipsis-multiline item-goods-name">{item.goods ? item.goods.goods_name : item.purchasing?.child?.length ? item.purchasing.goods_name + "等" : item.purchasing ? item.purchasing.goods_name : "" || ""}</div>
							<div className="row-center justify-between">
								<div className="row-center">
									{/* 订单来源0=普通 1=代购 */}
									{item.source ? <img className="buy" src={buy} alt="buy" /> : <img className="mercari" src={mercari} alt="mercari" />}
									{/* 商品数量 */}
									<div className="info-number">
										商品数量：
										{item.goods
											? item.goods.goods_num
											: item.purchasing
											? [item.purchasing]
													.concat(item.purchasing?.child || [])
													.map((val) => val.goods_num)
													.reduce((a, b) => a + b)
											: 1}
									</div>
								</div>

								{/* 店铺卖家 */}
								{status === 1 && item.source === 0 && item.goods?.type === "shop" && (
									<dvi className="row-center">
										<img className="shop" src={shop} alt="shop" />
										<div className="info-number">店铺卖家</div>
									</dvi>
								)}
							</div>
						</div>
					</div>
				) : (
					// 入库后拍照、合箱待审、出库待审
					<>
						{/* 增值服务/国际物流 */}
						<div className="info-text info-text-m">
							{status === 4 ? "国际物流" : "增值服务"}：<span>{(status === 4 ? item?.logistics?.dict?.name : item.is_task_photo && status === 2 ? "入库后拍照" : list.join("、")) || "无"}</span>
						</div>
						<div className="info-text info-text-footer">提交时间：{time ? moment(time * 1000).format("YYYY.MM.DD HH:mm:ss") : "暂无"}</div>
					</>
				)}
			</div>

			{/* 增值服务：无/入库前拍照 */}
			{status === 1 && (
				<div className="row-center service">
					增值服务：<span>{item.is_task_photo ? "入库前拍照" : "无"}</span>
				</div>
			)}
		</div>
	);
}

export default memo(ListItem, (prevPorps, nextProps) => prevPorps.item.id === nextProps.item.id);
