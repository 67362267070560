/*
 * @Author: showtan
 * @Date: 2024-07-02 10:13:57
 * @Version: 1.0
 * @LastEditTime: 2024-07-09 11:16:38
 * @LastEditors: showtan
 * @Description: home
 * @FilePath: /japan-h5/src/pages/home/index.js
 */
import HomeNavBar from "../../components/HomeNavBar";
import HomeContent from "../../components/HomeContent";
import HomeTabBar from "../../components/HomeTabBar";

/**
 * 首页
 * @returns
 */
export default function Home() {
	return (
		<div className="home-container">
			{/* 首页头部导航栏 */}
			<HomeNavBar />

			{/* 首页内容 */}
			<HomeContent />

			{/* 首页底部TabBar */}
			<HomeTabBar />
		</div>
	);
}
