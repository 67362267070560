/*
 * @Author: showtan
 * @Date: 2024-07-04 10:13:09
 * @Version: 1.0
 * @LastEditTime: 2024-08-20 17:36:04
 * @LastEditors: showtan
 * @Description: NumberInfo
 * @FilePath: /japan-h5/src/components/NumberInfo.js
 */
import { memo } from "react";

/**
 * 包裹编号、用户ID、订单编号
 * @param {*} param0
 * @returns
 */
function GoodsInfo({ data, noHeader }) {
	return (
		<>
			{/* 包裹编号、用户ID、订单编号 */}
			{!noHeader && <div className="detail-title">包裹编号：{data.delivery_identifier}</div>}
			<div className={`detail-cell-id${noHeader ? " detail-cell2" : ""}`}>
				<div className="detail-text">
					订单编号：<span>{data.order_no}</span>
				</div>
				<div className="detail-text">
					用户ID：<span>{data.user_id + "/" + data.user?.account}</span>
				</div>
			</div>
		</>
	);
}

export default memo(GoodsInfo);
