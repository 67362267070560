/*
 * @Author: showtan
 * @Date: 2024-07-02 16:46:53
 * @Version: 1.0
 * @LastEditTime: 2024-08-08 09:25:16
 * @LastEditors: showtan
 * @Description: OrderList
 * @FilePath: /japan-h5/src/components/OrderList.js
 */
import { useState, useRef, useEffect, memo, useCallback, useImperativeHandle } from "react";
import { InfiniteScroll, List, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import ListItem from "./ListItem";
import qs from "qs";
import { request } from "../utils/request";
import { useAuth } from "../utils/AuthContext";
import * as config from "../config";

const page_size = 15;

/**
 * 订单列表 status: 1待入库 2入库后拍照 3合箱待审 4出库待审
 * @returns
 */
function OrderList({ status, childrenRef }) {
	const navigate = useNavigate();
	const { tabs, setTabs } = useAuth();
	const [data, setData] = useState({
		hasMore: false, // 是否加载更多
		list: [], // 列表
	});
	const paramsRef = useRef({ isLoading: true, isFirst: true, page_no: 1 }); // 请求参数
	const tab = tabs.filter((item) => item.status === status)[0]; // TabBar

	useEffect(() => {
		getData();
	}, []);

	useImperativeHandle(childrenRef, () => ({
		search: (code) => {
			paramsRef.current.page_no = 1;
			getData(code);
		},
	}));

	/**
	 * 获取列表数据
	 * @param {String} delivery_identifier 包裹编号
	 */
	const getData = async (code) => {
		const page_no = paramsRef.current.page_no;
		const params = { page_no, page_size, type: status };
		code && (params.delivery_identifier = code);
		const res = await request("order_logistical/lists?" + qs.stringify(params), {
			method: "GET",
		});
		if (res?.code === 1 && res.data?.lists) {
			if (code && res.data.lists.length === 0) {
				Toast.show("未查询到此包裹，请重新输入~");
			}
			const { wait_storage, wait_take, wait_merge, wait_out } = res.data.extend || {};
			config.tabs[0].badge = String(wait_storage || "");
			config.tabs[1].badge = String(wait_take || "");
			config.tabs[2].badge = String(wait_merge || "");
			config.tabs[3].badge = String(wait_out || "");
			setTabs([...config.tabs]);
			paramsRef.current.isLoading = false;
			paramsRef.current.isFirst = false;
			setData((val) => ({
				hasMore: res.data.count > page_no * page_size,
				list: page_no === 1 ? res.data.lists : val.list.concat(res.data.lists),
			}));
		}
	};

	/**
	 * 加载更多
	 */
	const loadMore = useCallback(async () => {
		if (data.hasMore && !paramsRef.current.isLoading) {
			paramsRef.current.isLoading = true;
			++paramsRef.current.page_no;
			getData();
		}
	}, [data.hasMore]);

	/**
	 * 详情
	 */
	const detailHandler = async (item) => {
		if (item.logistical_id) {
			localStorage.setItem("detail", JSON.stringify(item));
			navigate(`${tab.detail}/${item.logistical_id}`);
		} else {
			Toast.show("订单异常~");
		}
	};

	return (
		<>
			{/* 待入库/入库后拍照/合箱待审/出库待审数量 */}
			<div className="content-header">
				{`${tab.title}${tab.badge ? `（${tab.badge}）` : ""}`}
				<div className="line-cell" style={{marginRight: tab.badge ? '0.7rem' : 0}} />
			</div>

			{/* 列表 */}
			<List>
				{data.list.map((item, index) => (
					<ListItem item={item} key={index} status={status} detailHandler={() => detailHandler(item)} />
				))}
			</List>

			{/* 滚动到底加载更多 */}
			{!paramsRef.current.isFirst && <InfiniteScroll loadMore={loadMore} hasMore={data.hasMore} />}
		</>
	);
}

export default memo(OrderList);
