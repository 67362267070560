/*
 * @Author: showtan
 * @Date: 2024-07-03 13:04:56
 * @Version: 1.0
 * @LastEditTime: 2024-08-22 11:13:13
 * @LastEditors: showtan
 * @Description: detail
 * @FilePath: /japan-h5/src/pages/out/detail/index.js
 */
import { useRef, useState } from "react";
import { Input, Mask, Toast } from "antd-mobile";
import { useParams, useNavigate } from "react-router-dom";
import { request } from "../../../utils/request";
import NavBar from "../../../components/NavBar";
import NumberInfo from "../../../components/NumberInfo";
import Table from "../../../components/Table";
import DetailFooter from "../../../components/DetailFooter";
import MaskFooter from "../../../components/MaskFooter";
import copy from "../../../assets/copy.png";
// import edit from "../../../assets/edit.png";
import selected from "../../../assets/selected.png";
import unselected from "../../../assets/unselected.png";
import success from "../../../assets/success.png";
import fail from "../../../assets/fail.png";

/**
 * 获取出库单商品
 * @param {*} list
 * @returns
 */
function getGoods(list) {
	let data = [];
	for (const val of list) {
		const purchasing = val.purchasing ? [val.purchasing].concat(val.purchasing?.child || []) : [];
		const goods = val.goods ? [val.goods] : [];
		data = data.concat(purchasing).concat(goods);
	}
	return data;
}

/**
 * 出库待审详情
 * @returns
 */
export default function OutDetail() {
	const navigate = useNavigate();
	const params = useParams(); // 参数订单ID
	const bottomRef = useRef(null); // 分页滚动到底部
	const detail = JSON.parse(localStorage.getItem("detail") || "{}");
	const data = String(detail.logistical_id) === params.id ? detail : {}; // 详情
	const [visible, setVisible] = useState(0); // 确认出库1、出库成功2、出库失败3、驳回4、出库已驳回5弹窗
	const [number, setNumber] = useState(""); // 物流单号
	const [chooseReason, setChooseReason] = useState(""); // 选择拒绝原因
	const [reason, setReason] = useState(""); // 输入拒绝原因
	const list = []; // 增值服务
	data.is_task_photo && list.push(`入库拍照服务：${Number(data.take_photo_fee)}日元`);
	data.is_firm && list.push(`外包装箱加固：${Number(data.firm_fee)}日元`);
	data.is_bubble_film && list.push(`气泡膜加固${Number(data.bubble_film_fee)}日元`);
	const goods = data.children?.length ? getGoods(data.children) : Array.isArray(data.goods) ? data.goods : data.goods ? [data.goods] : Array.isArray(data.purchasing) ? data.purchasing : data.purchasing ? [data.purchasing].concat(data.purchasing?.child || []) : []; // 出库商品
	const address = [...new Set(data.address?.region?.split(" ") || [])].join("") + (data.address?.address || ""); // 收货地址
	const [failMsg, setFailMsg] = useState(""); // 出库失败原因

	/**
	 * 弹窗确定出库
	 */
	function passHandler() {
		if (!number) {
			Toast.show("请输入物流单号~");
			return;
		}
		setVisible(1);
	}

	/**
	 * 确认
	 */
	async function onConfirm() {
		if (visible === 1) {
			// 确认出库
			const res = await request(
				"order_logistical/outWarehouse",
				{
					method: "post",
					body: JSON.stringify({ logistical_id: data.logistical_id, out_house_status: 3, delivery_no: number }),
				},
				true
			);
			if (res?.code === 1) {
				setVisible(2);
			} else {
				setFailMsg(res?.msg || "服务器异常");
				setVisible(3);
			}
		} else {
			// 驳回
			const out_house_reason = reason || (chooseReason !== "其他原因" ? chooseReason : "");
			if (out_house_reason) {
				const res = await request("order_logistical/outWarehouse", {
					method: "post",
					body: JSON.stringify({ logistical_id: data.logistical_id, out_house_status: 2, out_house_reason }),
				});
				res?.code === 1 && setVisible(5);
			} else {
				Toast.show("请输入驳回原因~");
			}
		}
	}

	/**
	 * 分页滚动到底部
	 */
	function scrollToBottom() {
		setTimeout(() => {
			bottomRef.current?.scrollIntoView({ behavior: "smooth" });
		}, 100);
	}

	/**
	 * 复制
	 */
	function copyHandler(text) {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				Toast.show("已复制~");
			})
			.catch((err) => {
				Toast.show("复制失败~");
			});
	}

	return (
		<>
			{/* header */}
			<NavBar title="出库详情" />

			{/* 内容 */}
			<div
				className="detail-content"
				style={{
					height: `calc(100vh - 7.71rem - ${window.isIphoneX ? "56px" : "20px"})`,
				}}
			>
				{/* 用户ID、订单编号 */}
				<NumberInfo data={data} noHeader />

				{/* 出库审核 */}
				<div className="detail-cell-label">出库审核</div>
				{/* 物流信息 */}
				<div className="detail-cell detail-cell3 detail-cell4">
					{/* 物流方式 */}
					<div className="row-center cell-bottom">
						<div className="form-label">物流方式</div>
						<div className="form-value">{data?.logistics?.dict?.name || "无"}</div>
					</div>
					{/* 国际物流单号 */}
					<div className="row-center">
						<div className="form-label form-label3">
							国际物流单号<span>*</span>
						</div>
						<Input className="form-input" placeholder="请输入物流单号" value={number} onChange={setNumber} />
					</div>
				</div>

				{/* 包裹信息 */}
				<div className="detail-cell-label">包裹信息</div>
				<div className="detail-cell detail-cell3">
					{/* 子订单包裹数量 */}
					<div className="detail-text detail-text3">
						子订单包裹数量：<span>{data.children?.length || 1}</span>
					</div>
					{/* 包裹编号 */}
					<div className="row detail-text detail-text3">
						包裹编号：<div className="flex1 form-value -margin">{data.children?.map((item) => item.delivery_identifier).join("、") || data.delivery_identifier}</div>
					</div>
					{/* 包裹重量 */}
					<div className="detail-text detail-text3">
						包裹重量（g）：<span>{data.weight}</span>
					</div>
				</div>

				{/* 费用明细 */}
				<div className="detail-cell-label">费用明细</div>
				<div className="detail-cell detail-cell3">
					{/* 订单总额 */}
					<div className="detail-text detail-text3">
						订单总额：<span>{Number(data.order_amount)}日元</span>
					</div>
					{/* 增值服务 */}
					<div className="detail-text detail-text3">
						增值服务：<span>{list.join("、") || "无"}</span>
					</div>
					{/* 合箱费 */}
					<div className="detail-text detail-text3">
						　合箱费：<span>{Number(data.merge_box_fee)}日元</span>
					</div>
					{/* 仓储费 */}
					<div className="detail-text detail-text3">
						　仓储费：<span>{Number(data.storage_fee)}日元</span>
					</div>
					{/* 国际运费 */}
					<div className="detail-text detail-text3">
						国际运费：
						<span>{data.logistics?.dict?.name ? `${data.logistics?.dict?.name} ${Number(data.delivery_fee)}日元` : "0日元"}</span>
					</div>
					{/* 补差 */}
					<div className="detail-text detail-text3">
						补　　差：<span>{Number(data.diff_fee)}日元</span>
					</div>
					{/* 抵扣 */}
					<div className="detail-text detail-text3">
						抵　　扣：<span>{Number(data.use_delivery_fee) ? `优惠券-${Number(data.use_delivery_fee)}日元` : Number(data.use_delivery_fee) ? `积分抵扣-${Number(data.use_points_fee)}日元` : "0日元"}</span>
					</div>
					{/* 实际支付 */}
					<div className="detail-text">
						实际支付：<span>{Number(data.payment_amount)}日元（已支付）</span>
					</div>
				</div>

				{/* 收货信息 */}
				<div className="detail-cell-label">收货信息</div>
				<div className="detail-cell detail-cell3">
					{/* 收货人姓名 */}
					<div className="detail-text detail-text3">
						收货人姓名：<span>{data.address?.consignee}</span>
						<img className="copy" src={copy} alt="copy" onClick={() => copyHandler(data.address?.consignee)} />
						{/* <img className="copy" src={edit} alt="edit" /> */}
					</div>
					{/* 收货人电话 */}
					<div className="detail-text detail-text3">
						收货人电话：<span>{data.address?.mobile}</span>
						<img className="copy" src={copy} alt="copy" onClick={() => copyHandler(data.address?.mobile)} />
						{/* <img className="copy" src={edit} alt="edit" /> */}
					</div>
					{/* 收货人地址 */}
					<div className="detail-text detail-text3">
						收货人地址：<span>{address}</span>
						<img className="copy" src={copy} alt="copy" onClick={() => copyHandler(address)} />
						{/* <img className="copy" src={edit} alt="edit" /> */}
					</div>
					<div className="detail-text detail-text4">申报内容</div>

					{/* 商品表格 */}
					<Table goods={goods} scrollToBottom={scrollToBottom} />
				</div>

				{/* 分页滚动到底部 */}
				<div ref={bottomRef} />
			</div>

			{/* footer */}
			<DetailFooter grayText="驳回" confirmText="确认出库" onGray={() => setVisible(4)} onConfirm={passHandler} />

			{/* 确认出库1、出库成功2、出库失败3、驳回4、出库已驳回5弹窗 */}
			<Mask visible={!!visible} onMaskClick={() => setVisible(0)}>
				<div className="row-center justify-center mask-container">
					<div className="mask-content">
						{/* title */}
						{visible === 1 ? <div className="mask-please no-border">确认出库吗？</div> : visible === 4 && <div className="mask-please">驳回申请订单将设为“待退款” 确认驳回吗</div>}

						{/* 出库成功2、出库失败3、出库已驳回5 */}
						{(visible === 2 || visible === 3 || visible === 5) && (
							<>
								<img className="status-img" src={visible === 2 ? success : fail} alt="tip" />
								{<div className="status-text">{visible === 2 ? "出库成功" : visible === 3 ? `出库失败（${failMsg}）` : "出库已驳回"}</div>}
							</>
						)}

						{/* 拒绝理由 */}
						{visible === 4 && (
							<>
								<div className="row-center reason-cell">
									<img
										className="selected-img selected-img-margin"
										onClick={() => {
											setChooseReason("所选物流不支持");
											setReason("");
										}}
										src={chooseReason === "所选物流不支持" ? selected : unselected}
										alt="select"
									/>
									所选物流不支持
								</div>
								<div className="row-center reason-cell">
									<img className="selected-img selected-img-margin" onClick={() => setChooseReason("其他原因")} src={chooseReason === "其他原因" ? selected : unselected} alt="select" />
									其他原因
								</div>
								<Input
									className="form-input form-input1"
									placeholder="请输入原因"
									value={reason}
									onChange={(val) => {
										val && setChooseReason("其他原因");
										setReason(val);
									}}
								/>
							</>
						)}

						{/* 取消、确定、返回按钮 */}
						<MaskFooter cancelText={visible === 2 || visible === 3 || visible === 5 ? "返回" : ""} onCancel={() => (visible !== 1 ? navigate(-1) : setVisible(0))} onConfirm={(visible === 1 || visible === 4) && onConfirm} />
					</div>
				</div>
			</Mask>
		</>
	);
}
