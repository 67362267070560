/*
 * @Author: showtan
 * @Date: 2024-07-02 11:13:34
 * @Version: 1.0
 * @LastEditTime: 2024-07-02 11:33:05
 * @LastEditors: showtan
 * @Description: 找不到页面
 * @FilePath: /japan-h5/src/pages/404/index.js
 */
export default function NotFound() {
	return <div>找不到页面~</div>;
};
