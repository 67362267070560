/*
 * @Author: showtan
 * @Date: 2024-07-02 10:17:25
 * @Version: 1.0
 * @LastEditTime: 2024-07-09 11:18:01
 * @LastEditors: showtan
 * @Description: router
 * @FilePath: /japan-h5/src/router/index.js
 */
import { HashRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/login";
import PrivateRoute from "../utils/PrivateRoute";
import Home from "../pages/home";
import Waiting from "../pages/waiting";
import WaitingDetail from "../pages/waiting/detail";
import Photograph from "../pages/photograph";
import PhotographDetail from "../pages/photograph/detail";
import Packaging from "../pages/packaging";
import PackagingDetail from "../pages/packaging/detail";
import Out from "../pages/out";
import OutDetail from "../pages/out/detail";
import NotFound from "../pages/404";

export default function Router() {
	return (
		<HashRouter>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/" element={<PrivateRoute element={<Home />} />}>
					<Route index element={<Waiting />} />
					<Route path="Photograph" element={<Photograph />} />
					<Route path="Packaging" element={<Packaging />} />
					<Route path="Out" element={<Out />} />
				</Route>
				<Route path="/waitingDetail/:id" element={<WaitingDetail />} />
				<Route path="/photographDetail/:id" element={<PhotographDetail />} />
				<Route path="/packagingDetail/:id" element={<PackagingDetail />} />
				<Route path="/outDetail/:id" element={<OutDetail />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</HashRouter>
	);
}
