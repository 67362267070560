/*
 * @Author: showtan
 * @Date: 2024-07-03 14:11:36
 * @Version: 1.0
 * @LastEditTime: 2024-07-03 14:39:05
 * @LastEditors: showtan
 * @Description: NavBar
 * @FilePath: /japan-h5/src/components/NavBar.js
 */
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import back from "../assets/back.png";

/**
 * 导航栏
 * @returns
 */
function NavBar({ title }) {
	const navigate = useNavigate();

	return (
		<div className="row-center justify-between navbar" style={{ height: `calc(3.14rem + ${window.isIphoneX ? "44px" : "20px"})`, paddingTop: `calc(${window.isIphoneX ? "44px" : "20px"})` }}>
			{/* 返回 */}
			<div className="row-center justify-center navbar-back" onClick={() => navigate(-1)}>
				<img className="navbar-back-img" src={back} alt="back" />
			</div>
			{/* 标题 */}
			<div className="single-line-ellipsis navbar-title">{title}</div>
			<div className="navbar-back" />
		</div>
	);
}

export default memo(NavBar, () => true);
