/*
 * @Author: showtan
 * @Date: 2024-07-03 13:05:12
 * @Version: 1.0
 * @LastEditTime: 2024-08-20 17:46:40
 * @LastEditors: showtan
 * @Description: detail
 * @FilePath: /japan-h5/src/pages/photograph/detail/index.js
 */
import { useState, useRef } from "react";
import { Mask, Toast } from "antd-mobile";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "../../../utils/request";
import NavBar from "../../../components/NavBar";
import GoodsInfo from "../../../components/GoodsInfo";
import NumberInfo from "../../../components/NumberInfo";
import ImageUpload from "../../../components/ImageUpload";
import DetailFooter from "../../../components/DetailFooter";
import MaskFooter from "../../../components/MaskFooter";
import success from "../../../assets/success.png";
import fail from "../../../assets/fail.png";

/**
 * 入库后拍照详情
 * @returns
 */
export default function PhotographDetail() {
	const navigate = useNavigate();
	const params = useParams(); // 参数订单ID
	const detail = JSON.parse(localStorage.getItem("detail") || "{}");
	const data = String(detail.logistical_id) === params.id ? detail : {};
	const uploadRef = useRef(); // 上传
	const [visible, setVisible] = useState(0); // 确认提交1、提交成功2、提交失败原因弹窗

	/**
	 * 入库后拍照
	 */
	function passHandler() {
		const in_imgs = uploadRef.current?.getFile() || [];
		if (!in_imgs.length) {
			Toast.show("您还没有上传拍照~");
			return;
		}
		setVisible(1);
	}

	/**
	 * 确认提交
	 */
	async function onConfirm() {
		const in_imgs = uploadRef.current?.getFile() || [];
		const res = await request(
			"order_logistical/photograph",
			{
				method: "post",
				body: JSON.stringify({ logistical_id: data.logistical_id, in_imgs }),
			},
			true
		);
		if (res?.code === 1) {
			setVisible(2);
		} else {
			setVisible(res?.msg || "服务器异常");
		}
	}

	return (
		<>
			{/* header */}
			<NavBar title="入库后拍照" />

			{/* 内容 */}
			<div
				className="detail-content"
				style={{
					height: `calc(100vh - 7.71rem - ${window.isIphoneX ? "56px" : "20px"})`,
				}}
			>
				{/* 包裹编号、用户ID、订单编号 */}
				<NumberInfo data={data} />

				{/* 商品信息 */}
				<GoodsInfo data={data} isAfter />

				{/* 操作拍照 */}
				<div className="detail-cell-label">操作拍照</div>
				<div className="detail-cell detail-cell1">
					{/* 拍照服务 */}
					<div className="row">
						<div className="form-label form-label1">
							拍照服务
							<div>3张照片</div>
						</div>
						<ImageUpload maxCount={3} uploadRef={uploadRef} />
					</div>
				</div>
			</div>

			{/* footer */}
			<DetailFooter grayText="返回" confirmText="确认提交" onGray={() => navigate(-1)} onConfirm={passHandler} />

			{/* 确认提交1、提交成功2、提交失败3弹窗 */}
			<Mask visible={!!visible} onMaskClick={() => setVisible(0)}>
				<div className="row-center justify-center mask-container">
					<div className="mask-content">
						{visible === 1 && <div className="mask-please no-border">确认提交吗？</div>}

						{/* 提交成功2、提交失败原因 */}
						{visible && visible !== 1 && (
							<>
								<img className="status-img" src={visible === 2 ? success : fail} alt="tip" />
								{visible === 2 ? (
									<div className="status-text">提交成功</div>
								) : (
									<div className="status-text">
										提交失败<span className="status-reason">（{visible}）</span>
									</div>
								)}
							</>
						)}

						{/* 取消、确定、返回按钮 */}
						<MaskFooter cancelText={visible === 1 ? "" : "返回"} onCancel={() => (visible && visible !== 1 ? navigate(-1) : setVisible(0))} onConfirm={visible === 1 && onConfirm} />
					</div>
				</div>
			</Mask>
		</>
	);
}
