/*
 * @Author: showtan
 * @Date: 2024-07-04 16:17:28
 * @Version: 1.0
 * @LastEditTime: 2024-09-06 15:09:18
 * @LastEditors: showtan
 * @Description: Table
 * @FilePath: /japan-h5/src/components/Table.js
 */
import { memo, useState } from "react";
import { Image, ImageViewer } from "antd-mobile";
import { LeftOutline, RightOutline } from "antd-mobile-icons";

const pageSize = 6;

/**
 * 表格
 * @returns
 */
function Table({ goods, scrollToBottom }) {
	const [page, setPage] = useState(1); // 页
	const [visible, setVisible] = useState(false); // 图片预览弹窗
	const [images, setImages] = useState([]); // 图片预览的图片

	/**
	 * 上一页
	 */
	function prevHandler() {
		if (page > 1) {
			setPage((val) => val - 1);
			// 最后一页且最后一页不满
			if (page === Math.ceil(goods.length / pageSize) && page * pageSize !== goods.length) {
				scrollToBottom();
			}
		}
	}

	/**
	 * 下一页
	 */
	function nextHandler() {
		if (goods?.length && page * pageSize < goods.length) {
			setPage((val) => val + 1);
		}
	}

	/**
	 * 图片预览
	 * @param {*} photo
	 */
	function previewImage(photo) {
		setImages([]);
		setVisible(true);
		if (typeof photo === "object") {
			const list = [];
			for (const key in photo) {
				list.push(photo[key]);
				setImages(list);
			}
		} else {
			setImages([photo]);
		}
	}

	return (
		<>
			{/* 表头 */}
			<div className="row-center table table-thead">
				<div className="table-thead-1">商品图片</div>
				<div className="table-thead-1">商品名称</div>
				<div className="table-thead-2">商品总数</div>
				<div className="table-thead-3">商品单价</div>
			</div>

			{/* 行 */}
			{goods.slice((page - 1) * pageSize, page * pageSize).map((item, index) => (
				<div key={index} className="row-center table table-row">
					<div className="table-thead-1 row-center justify-center">
						<Image onClick={() => previewImage(item.goods_photos)} className="table-thead-image" lazy src={(typeof item.goods_photos === "object" ? item.goods_photos?.[0] : item.goods_photos) || "/404"} fit="cover" />
					</div>
					<div className="table-thead-1 min-font single-line-ellipsis">{item?.goods_name}</div>
					<div className="table-thead-2 min-font">{item?.goods_num}</div>
					<div className="table-thead-3 min-font">{Number(item?.goods_price)}</div>
				</div>
			))}

			{/* 分页 */}
			<div className="row-center table-pagination">
				{/* 上一页 */}
				<div onClick={prevHandler} className="row-center arrow-cell">
					<LeftOutline />
				</div>
				{/* 页码 */}
				<div className="row-center pagination-cell">{page}</div>
				{/* 下一页 */}
				<div onClick={nextHandler} className="row-center arrow-cell">
					<RightOutline />
				</div>
			</div>

			{/* 图片预览 */}
			<ImageViewer.Multi
				classNames={{
					mask: "customize-mask",
					body: "customize-body",
				}}
				images={images}
				visible={visible}
				onClose={() => setVisible(false)}
			/>
		</>
	);
}

export default memo(Table);
