/*
 * @Author: showtan
 * @Date: 2024-07-02 09:30:23
 * @Version: 1.0
 * @LastEditTime: 2024-07-31 14:43:47
 * @LastEditors: showtan
 * @Description: request
 * @FilePath: /japan-h5/src/utils/request.js
 */
import { Toast } from "antd-mobile";
import * as config from "../config";
import { createSign } from "./sign";

/**
 * request
 * @param  {string} url
 * @param  {any} options
 * @return Promise
 */
export async function request(url, options, notShow) {
	try {
		const res = await (await fetch(config.host + url, createSign(options, url))).json();
		if (res.code === -1) {
			global.navigate("/login");
		} else if (res.code !== 1) {
			!notShow && Toast.show(res?.msg || "服务器异常~");
		}
		return res;
	} catch (error) {
		Toast.show("服务器异常~");
		console.error("Error fetching data:", error);
	}
}

/**
 * 文件/图片上传
 * @param  {string} url
 * @param  {any} options
 * @return Promise
 */
export async function xhr(url, options) {
	return new Promise((resolve, reject) => {
		const { method, onProgress } = options;
		const xhrReq = new XMLHttpRequest();
		xhrReq.open(method || "POST", config.host + url);
		const headers = createSign(options, url).headers;
		for (let key in headers) {
			xhrReq.setRequestHeader(key, headers[key]);
		}
		xhrReq.onload = (e) => resolve(e);
		xhrReq.onerror = reject;
		if (xhrReq.upload && onProgress) {
			xhrReq.upload.onprogress = onProgress; // 上传
		}
		if ("onprogerss" in xhrReq && onProgress) {
			xhrReq.onprogress = onProgress; // 下载
		}
		xhrReq.send(options.body);
		xhrReq.onreadystatechange = function () {
			// 数据全部返回的判断
			if (xhrReq.readyState === 4) {
				resolve(JSON.parse(xhrReq.response));
			}
		};
	});
}
