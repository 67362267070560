/*
 * @Author: showtan
 * @Date: 2024-07-04 10:09:54
 * @Version: 1.0
 * @LastEditTime: 2024-09-05 09:29:51
 * @LastEditors: showtan
 * @Description: GoodsInfo
 * @FilePath: /japan-h5/src/components/GoodsInfo.js
 */
import { memo } from "react";
import { Image } from "antd-mobile";
import mercari from "../assets/mercari.png";
import buy from "../assets/buy.png";
import shop from "../assets/shop.png";

const getGoods = (data) => {
	let list = [];
	if (data.children.length) {
		for (const val of data.children || []) {
			if (val.goods) {
				list.push(val.goods);
			} else if (val.purchasing) {
				if (Array.isArray(val.purchasing)) {
					list = list.concat(val.purchasing).concat(val.purchasing?.child || []);
				} else {
					list.push(val.purchasing);
					list.push(val.purchasing?.child || []);
				}
			}
		}
	} else {
		list = Array.isArray(data.goods) ? data.goods : data.goods ? [data.goods] : Array.isArray(data.purchasing) ? data.purchasing : data.purchasing ? [data.purchasing].concat(data.purchasing?.child || []) : [];
	}
	return list;
};

/**
 * 商品信息
 * @param {*} isAfter 订单支付后（物流单）
 * @returns
 */
function GoodsInfo({ data, isAfter, status }) {
	const goods = getGoods(data);

	return (
		<>
			<div className="detail-cell-label">商品信息</div>
			<div className="detail-cell">
				<div className="row-center">
					{/* 商品缩略图 */}
					<Image lazy src={(isAfter ? (typeof goods[0]?.goods_photos === "object" ? goods[0]?.goods_photos?.[0] : goods[0]?.goods_photos || "") : data.goods ? data.goods.goods_photos?.[0] : data.purchasing ? data.purchasing?.goods_photos : "") || "/404"} fit="cover" className="image" />
					<div className="col justify-between item-goods">
						{/* 商品名称（isAfter物流单用children、goods商品、purchasing代拍） */}
						<div className="ellipsis-multiline item-goods-name">{isAfter ? (goods.length > 1 ? goods[0].goods_name + "等" : goods[0]?.goods_name || "") : data.goods ? data.goods.goods_name : data.purchasing?.child?.length ? data.purchasing?.goods_name + "等" : data.purchasing?.goods_name || ""}</div>
						<div className="row-center justify-between">
							<div className="row-center">
								{/* 订单来源0=普通 1=代购 */}
								{data.source ? <img className="buy" src={buy} alt="buy" /> : <img className="mercari" src={mercari} alt="mercari" />}

								{/* 商品数量 */}
								<div className="info-number">
									商品数量：
									{isAfter
										? goods.map((item) => item.goods_num).reduce((a, b) => a + b)
										: data.goods
										? data.goods.goods_num
										: data.purchasing
										? [data.purchasing]
												.concat(data.purchasing?.child || [])
												.map((item) => item.goods_num)
												.reduce((a, b) => a + b)
										: 1}
								</div>
							</div>

							{/* 店铺卖家 */}
							{status === 1 && data.source === 0 && data.goods.type === "shop" && (
								<div className="row-center">
									<img className="shop" src={shop} alt="shop" />
									<div className="info-number">店铺卖家</div>
								</div>
							)}
						</div>
					</div>
				</div>

				{/* 增值服务 */}
				<div className="row-center service service-top">
					增值服务：<span>{data.is_task_photo ? `入库${isAfter ? "后" : "前"}拍照` : "无"}</span>
				</div>
			</div>
		</>
	);
}

export default memo(GoodsInfo);
