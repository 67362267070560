/*
 * @Author: showtan
 * @Date: 2024-07-02 10:46:29
 * @Version: 1.0
 * @LastEditTime: 2024-07-03 11:10:38
 * @LastEditors: showtan
 * @Description: 合箱待审
 * @FilePath: /japan-h5/src/pages/packaging/index.js
 */
import OrderList from "../../components/OrderList";

/**
 * 合箱待审
 * @returns
 */
export default function Packaging() {
	return <OrderList status={3} />;
}
