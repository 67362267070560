/*
 * @Author: showtan
 * @Date: 2024-07-02 10:45:58
 * @Version: 1.0
 * @LastEditTime: 2024-07-03 11:11:41
 * @LastEditors: showtan
 * @Description: 入库后拍照
 * @FilePath: /japan-h5/src/pages/photograph/index.js
 */
import OrderList from "../../components/OrderList";

/**
 * 入库后拍照
 * @returns
 */
export default function Photograph() {
	return <OrderList status={2} />;
}
