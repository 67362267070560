/*
 * @Author: showtan
 * @Date: 2024-07-09 09:41:19
 * @Version: 1.0
 * @LastEditTime: 2024-07-09 11:19:01
 * @LastEditors: showtan
 * @Description: HomeContent
 * @FilePath: /japan-h5/src/components/HomeContent.js
 */
import { memo } from "react";
import { Outlet } from "react-router-dom";

/**
 * 首页内容
 * @returns
 */
function HomeContent() {
	return (
		<div
			style={{
				height: `calc(100vh - ${window.isIphoneX ? "56px" : "20px"} - 7.92rem)`,
				marginTop: `calc(${window.isIphoneX ? "44px" : "20px"} - 6.36rem)`,
			}}
			className="home-content"
		>
			<Outlet />
		</div>
	);
}

export default memo(HomeContent);
