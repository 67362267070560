/*
 * @Author: showtan
 * @Date: 2024-07-02 10:44:22
 * @Version: 1.0
 * @LastEditTime: 2024-08-01 14:41:36
 * @LastEditors: showtan
 * @Description: waiting
 * @FilePath: /japan-h5/src/pages/waiting/index.js
 */
import { useRef, useState } from "react";
import { Toast, Mask, Input } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import OrderList from "../../components/OrderList";
import MaskFooter from "../../components/MaskFooter";
import inquire from "../../assets/inquire.png";
import { request } from "../../utils/request";

/**
 * 待入库
 * @returns
 */
export default function Waiting() {
	const navigate = useNavigate();
	const [visible, setVisible] = useState(false); // 入库查询弹窗
	const [code, setCode] = useState(""); // 包裹编号
	const childrenRef = useRef(null);

	/**
	 * 入库查询
	 */
	async function inquireHanlder() {
		if (code) {
			// childrenRef.current?.search?.(code);
			const res = await request("order_logistical/lists?type=1&delivery_identifier=" + code, {
				method: "GET",
			});
			if (res?.code === 1 && res.data?.lists) {
				const item = res.data.lists[0];
				if (item?.logistical_id) {
					localStorage.setItem("detail", JSON.stringify(item));
					navigate(`/waitingDetail/${item.logistical_id}`);
				} else {
					Toast.show("未查询到此包裹，请重新输入~");
				}
			}
		} else {
			Toast.show("请输入~");
		}
	}

	return (
		<>
			{/* 入库查询 */}
			<img onClick={() => setVisible(true)} className="inquire-img" src={inquire} alt="inquire" />

			{/* 订单列表 */}
			<OrderList status={1} childrenRef={childrenRef} />

			{/* 入库查询弹窗 */}
			<Mask visible={visible} onMaskClick={() => setVisible(false)}>
				<div className="row-center justify-center mask-container">
					<div className="mask-content">
						<div className="mask-please">请输入包裹编号</div>

						{/* 输入包裹编号 */}
						<Input className="code-input" value={code} onChange={setCode} clearable placeholder="请输入包裹编号" />

						{/* 取消、确定按钮 */}
						<MaskFooter variable={code} onCancel={() => setVisible(false)} onConfirm={inquireHanlder} />
					</div>
				</div>
			</Mask>
		</>
	);
}
