/*
 * @Author: showtan
 * @Date: 2024-07-03 10:24:35
 * @Version: 1.0
 * @LastEditTime: 2024-07-31 14:40:54
 * @LastEditors: showtan
 * @Description:
 * @FilePath: /japan-h5/src/components/MaskFooter.js
 */
import { memo } from "react";
import { Button } from "antd-mobile";

/**
 * 弹窗底部按钮
 * @returns
 */
function MaskFooter({ cancelText, confirmText, onCancel, onConfirm }) {
	return (
		<div className="row-center justify-center">
			{!!onCancel && (
				<Button onClick={onCancel} className="footer-btn cancel-btn" shape="rounded">
					{cancelText || "取消"}
				</Button>
			)}
			{!!onConfirm && (
				<Button onClick={onConfirm} className="footer-btn" shape="rounded" color="primary">
					{confirmText || "确定"}
				</Button>
			)}
		</div>
	);
}

export default memo(MaskFooter);
