/*
 * @Author: showtan
 * @Date: 2024-07-04 11:37:58
 * @Version: 1.0
 * @LastEditTime: 2024-07-04 11:38:04
 * @LastEditors: showtan
 * @Description: PrivateRoute
 * @FilePath: /japan-h5/src/utils/PrivateRoute.js
 */
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const PrivateRoute = ({ element }) => {
	const { isLoggedIn } = useAuth();

	return isLoggedIn ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
