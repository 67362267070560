/*
 * @Author: showtan
 * @Date: 2024-07-03 15:39:34
 * @Version: 1.0
 * @LastEditTime: 2024-08-08 10:49:03
 * @LastEditors: showtan
 * @Description: ImageUpload
 * @FilePath: /japan-h5/src/components/ImageUpload.js
 */
import React, { memo, useEffect, useImperativeHandle, useState } from "react";
import { ImageUploader, ActionSheet } from "antd-mobile";
import photo from "../assets/photo.png";

/**
 * 图片上传
 * @param {*} param0
 * @returns
 */
function ImageUpload({ maxCount, uploadRef }) {
	const userInfo = JSON.parse(localStorage.getItem("userInfo") || null); // 用户信息（上传图片所需token）
	const [fileList, setFileList] = useState([]); // 上传列表
	const [visible, setVisible] = useState(false);
	const actions = [
		{
			text: "拍照",
			key: "capture",
			// app上传
			onClick: async () => window.ReactNativeWebView?.postMessage("capture___" + userInfo?.token),
		},
		{
			text: "从手机相册选择",
			key: "library",
			// app上传
			onClick: () => window.ReactNativeWebView?.postMessage("library___" + userInfo?.token),
		},
	];

	useImperativeHandle(uploadRef, () => ({
		getFile: () => fileList.map((item) => item.url),
	}));

	useEffect(() => {
		/**
		 * 收到的url处理
		 * @param {*} event
		 */
		const handleMessage = (event) => {
			if (typeof event.data === "string" && event.data.includes("http")) {
				setFileList((val) => {
					const list = [...val, { url: event.data }];
					if (list.length >= maxCount) {
						setVisible(false);
					}
					return list;
				});
			}
		};
		// ios
		window.addEventListener("message", handleMessage);
		// android
		window.document.addEventListener("message", handleMessage);
		return () => {
			window.removeEventListener("message", handleMessage);
			window.document.removeEventListener("message", handleMessage);
		};
	}, []);

	return (
		<>
			<ImageUploader style={{ "--cell-size": "4.07rem", "--gap-horizontal": "0.57rem", "--gap-vertical": 0, marginRight: "-1.5rem" }} value={fileList} onChange={setFileList} maxCount={maxCount} disableUpload>
				{/* 自定义 */}
				<div onClick={() => setVisible(true)} className="row-center justify-center photo-cell">
					<img className="photo-img" src={photo} alt="photo-img" />
				</div>
			</ImageUploader>
			{/* 拍照、从相册选择面板 */}
			<ActionSheet cancelText="取消" visible={visible} actions={actions} onClose={() => setVisible(false)} />
		</>
	);
}

export default memo(ImageUpload);
