/*
 * @Author: showtan
 * @Date: 2024-07-03 14:29:18
 * @Version: 1.0
 * @LastEditTime: 2024-07-04 15:06:10
 * @LastEditors: showtan
 * @Description: DetailFooter
 * @FilePath: /japan-h5/src/components/DetailFooter.js
 */
import { memo } from "react";
import { Button } from "antd-mobile";

/**
 * 详情底部按钮
 * @returns
 */
function DetailFooter({ grayText, confirmText, onGray, onConfirm }) {
	return (
		<div
			className="row-center justify-center"
			style={{
				height: `calc(4.57rem + ${window.isIphoneX ? "12px" : "0px"})`,
				paddingBottom: window.isIphoneX ? 12 : 0,
			}}
		>
			{/* 取消/返回...灰色按钮 */}
			<Button onClick={onGray} className="gray-btn" shape="rounded">
				{grayText}
			</Button>
			{/* 确定/提交...按钮 */}
			<Button onClick={onConfirm} className="confirm-btn" shape="rounded" color="primary">
				{confirmText}
			</Button>
		</div>
	);
}

export default memo(DetailFooter);
