/*
 * @Author: showtan
 * @Date: 2024-07-09 09:43:04
 * @Version: 1.0
 * @LastEditTime: 2024-07-31 19:01:51
 * @LastEditors: showtan
 * @Description: HomeTabBar
 * @FilePath: /japan-h5/src/components/HomeTabBar.js
 */
import { memo } from "react";
import { TabBar } from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";

/**
 * 首页底部TabBar
 * @returns
 */
function HomeTabBar() {
	const { tabs } = useAuth();
	global.navigate = useNavigate();
	const { pathname } = useLocation();

	/**
	 * TabBar onChange
	 * @param {string} key
	 */
	const onChange = (key) => {
		global.navigate(key);
	};

	return (
		<TabBar style={{ paddingBottom: window.isIphoneX ? 12 : 0 }} className="home-bottom" activeKey={pathname} onChange={onChange}>
			{tabs?.map((item) => (
				<TabBar.Item className={pathname === item.key && "active-border"} key={item.key} title={item.title} badge={item.badge} />
			))}
		</TabBar>
	);
}

export default memo(HomeTabBar);
