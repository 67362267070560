/*
 * @Author: showtan
 * @Date: 2024-07-02 10:14:21
 * @Version: 1.0
 * @LastEditTime: 2024-07-31 14:08:42
 * @LastEditors: showtan
 * @Description: login
 * @FilePath: /japan-h5/src/pages/login/index.js
 */
import { useState } from "react";
import { Toast, Button, Input } from "antd-mobile";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
import { request } from "../../utils/request";
import loginBg from "../../assets/loginBg.png";
import logo from "../../assets/logo.png";

/**
 * 登录页
 * @returns
 */
export default function Login() {
	global.navigate = useNavigate();
	const { login } = useAuth();
	const loginInfo = JSON.parse(localStorage.getItem("loginInfo") || null); // 登录信息
	const [account, setAccount] = useState(loginInfo?.account); // 登录账号
	const [password, setPassword] = useState(loginInfo?.password); // 登录密码
	const [visible, setVisible] = useState(false); // 密码显示、遮住
	const [content, setContent] = useState(false); // 添加虚拟填充

	/**
	 * 登录
	 */
	async function loginHandler() {
		if (account && password) {
			const res = await request("login/account", {
				method: "post",
				body: JSON.stringify({ account, password, terminal: 1 }),
			});
			if (res?.code === 1 && res.data) {
				Toast.show("登录成功~");
				// 存储登录信息、用户信息
				localStorage.setItem("loginInfo", JSON.stringify({ account, password }));
				localStorage.setItem("userInfo", JSON.stringify(res.data));
				login();
				// 跳转首页
				setTimeout(() => {
					global.navigate("/");
				}, 500);
			}
		} else {
			Toast.show("请输入完整~");
		}
	}

	return (
		<>
			{/* 登录页背景 */}
			<img className="login-bg" src={loginBg} alt="loginBg" />

			{/* logo */}
			<img className="login-logo" src={logo} alt="logo" />
			<div className="login-content">
				{/* 账号 */}
				<div className="login-label">账号</div>
				<Input
					clearable
					value={account}
					onChange={setAccount}
					className="login-input"
					placeholder="请输入登录账号"
					onBlur={() => setContent(false)}
					onFocus={(e) => {
						setContent(true);
						setTimeout(() => {
							e?.target?.scrollIntoView({ block: "center", behavior: "smooth" });
						}, 400);
					}}
				/>

				{/* 密码 */}
				<div className="login-label">密码</div>
				<div className="login-input login-password">
					<Input
						clearable
						value={password}
						onChange={setPassword}
						placeholder="请输入密码"
						onBlur={() => setContent(false)}
						className="login-password-input"
						type={visible ? "text" : "password"}
						onFocus={(e) => {
							setContent(true);
							setTimeout(() => {
								e?.target?.scrollIntoView({ block: "center", behavior: "smooth" });
							}, 400);
						}}
						enterKeyHint="enter"
						onEnterPress={loginHandler}
					/>
					<div className="login-eye" onClick={() => setVisible(!visible)}>
						{!visible ? <EyeInvisibleOutline className="21321" /> : <EyeOutline />}
					</div>
				</div>

				{/* 登录按钮 */}
				<Button className="login-button" onClick={loginHandler} block shape="rounded" color="primary">
					登录
				</Button>

				{content && <div style={{ height: 500 }} />}
			</div>
		</>
	);
}
