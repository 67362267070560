/*
 * @Author: showtan
 * @Date: 2024-07-02 14:46:19
 * @Version: 1.0
 * @LastEditTime: 2024-07-09 11:18:53
 * @LastEditors: showtan
 * @Description: HomeNavBar
 * @FilePath: /japan-h5/src/components/HomeNavBar.js
 */
import { memo } from "react";
import header from "../assets/header.png";

/**
 * 首页头部导航栏
 * @returns
 */
function HomeNavBar() {
	return (
		<div className="header">
			{/* 背景 */}
			<img className="header-bg" src={header} alt="header" />
			<div style={{ paddingTop: window.isIphoneX ? 44 : 20 }} className="header-title">
				日淘系统
			</div>
		</div>
	);
}

export default memo(HomeNavBar);
