/*
 * @Author: showtan
 * @Date: 2024-07-03 13:03:20
 * @Version: 1.0
 * @LastEditTime: 2024-09-09 09:49:34
 * @LastEditors: showtan
 * @Description: detail
 * @FilePath: /japan-h5/src/pages/waiting/detail/index.js
 */
import { useState, useRef, useEffect } from "react";
import { Input, Toast, Mask, Dialog } from "antd-mobile";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "../../../utils/request";
import NavBar from "../../../components/NavBar";
import GoodsInfo from "../../../components/GoodsInfo";
import NumberInfo from "../../../components/NumberInfo";
import ImageUpload from "../../../components/ImageUpload";
import DetailFooter from "../../../components/DetailFooter";
import MaskFooter from "../../../components/MaskFooter";
import selected from "../../../assets/selected.png";
import unselected from "../../../assets/unselected.png";
import success from "../../../assets/success.png";
import fail from "../../../assets/fail.png";

/**
 * 待入库详情
 * @returns
 */
export default function WaitingDetail() {
	const navigate = useNavigate();
	const params = useParams(); // 参数订单ID
	const detail = JSON.parse(localStorage.getItem("detail") || "{}");
	const data = String(detail.logistical_id) === params.id ? detail : {};
	const uploadRef = useRef(); // 上传
	const [operateParams, setOperateParams] = useState({
		weight: "", // 包裹重量
		diff_fee: "", // 补差金额
		diff_reason: "", // 补差原因
		is_comment: 1, // 选择煤炉确认收货+评价
	});
	const [visible, setVisible] = useState(0); // 确认入库1、入库成功2、入库失败原因弹窗
	const [content, setContent] = useState(false); // 添加虚拟填充

	useEffect(() => {
		const refundStatus = data.refund_status;
		if (refundStatus === 1 || refundStatus === 2 || refundStatus === 5) {
			Dialog.alert({
				title: "订单入库",
				content: `此订单${refundStatus === 1 ? "退款审核中" : refundStatus === 2 ? "退款中" : "已退款"}，请确认是否入库？`,
				onConfirm: () => {},
			});
		}
	}, []);

	/**
	 * 入库
	 */
	function passHandler() {
		const in_imgs = uploadRef.current?.getFile() || [];
		if (!operateParams.weight) {
			Toast.show("请输入包裹重量~");
			return;
		}
		if (data.is_task_photo === 1 && !in_imgs.length) {
			Toast.show("您还没有上传拍照~");
			return;
		}
		setVisible(1);
	}

	/**
	 * 确认入库
	 */
	async function onConfirm() {
		const in_imgs = uploadRef.current?.getFile() || [];
		const res = await request(
			"order_logistical/inWarehouse",
			{
				method: "post",
				body: JSON.stringify({ logistical_id: data.logistical_id, ...operateParams, in_imgs }),
			},
			true
		);
		if (res?.code === 1) {
			setVisible(2);
		} else {
			setVisible(res?.msg || "服务器异常");
		}
	}

	return (
		<>
			{/* header */}
			<NavBar title="待入库详情" />

			{/* 内容 */}
			<div
				className="detail-content"
				style={{
					height: `calc(100vh - 7.71rem - ${window.isIphoneX ? "56px" : "20px"})`,
				}}
			>
				{/* 包裹编号、用户ID、订单编号 */}
				<NumberInfo data={data} />

				{/* 商品信息 */}
				<GoodsInfo data={data} status={1} />

				{/* 操作入库 */}
				<div className="detail-cell-label">操作入库</div>
				<div className="detail-cell">
					{/* 包裹重量 */}
					<div className="row-center cell-bottom">
						<div className="form-label">
							包裹重量（g）<span>*</span>
						</div>
						<Input
							type="number"
							className="form-input"
							placeholder="请输入包裹重量"
							value={operateParams.weight}
							onChange={(weight) => setOperateParams({ ...operateParams, weight })}
							onBlur={() => setContent(false)}
							onFocus={(e) => {
								setContent(true);
								setTimeout(() => {
									e?.target?.scrollIntoView({ block: "center", behavior: "smooth" });
								}, 400);
							}}
						/>
					</div>
					{/* 入库前拍照 */}
					<div className="row cell-bottom">
						<div className="form-label">
							入库前拍照
							<div>3张照片</div>
						</div>
						<ImageUpload maxCount={3} uploadRef={uploadRef} />
					</div>
					{/* 补差金额、原因 */}
					<div className="row">
						<div className="form-label">
							补差（日元）
							<div>选填</div>
						</div>
						<div className="flex1">
							<Input
								type="number"
								className="form-input cell-bottom1"
								placeholder="请输入补差金额"
								value={operateParams.diff_fee}
								onChange={(diff_fee) => setOperateParams({ ...operateParams, diff_fee })}
								onBlur={() => setContent(false)}
								onFocus={(e) => {
									setContent(true);
									setTimeout(() => {
										e?.target?.scrollIntoView({ block: "center", behavior: "smooth" });
									}, 400);
								}}
							/>
							<Input
								className="form-input"
								placeholder="请输入补差原因"
								value={operateParams.diff_reason}
								onChange={(diff_reason) => setOperateParams({ ...operateParams, diff_reason })}
								onBlur={() => setContent(false)}
								onFocus={(e) => {
									setContent(true);
									setTimeout(() => {
										e?.target?.scrollIntoView({ block: "center", behavior: "smooth" });
									}, 400);
								}}
							/>
						</div>
					</div>
				</div>

				{content && <div style={{ height: 500 }} />}
			</div>

			{/* footer */}
			<DetailFooter grayText="返回查询" confirmText="确认入库" onGray={() => navigate(-1)} onConfirm={passHandler} />

			{/* 确认入库1、入库成功2、入库失败3弹窗 */}
			<Mask visible={!!visible} onMaskClick={() => setVisible(0)}>
				<div className="row-center justify-center mask-container">
					<div className="mask-content">
						{/* 煤炉、代买确认入库1 */}
						{visible === 1 && (
							<>
								{/* 订单来源0=普通 1=代购 煤炉确认有border + 确认收货+评价（不能为店铺卖家shop） */}
								<div className={`mask-please${data?.source === 1 ? "" : " no-border"}`}>确认入库吗？</div>
								{data?.source === 0 && data?.goods?.type === "mercari" && (
									<div className="row-center justify-center selected-cell">
										煤炉确认收货+评价
										<img className="selected-img" onClick={() => setOperateParams({ ...operateParams, is_comment: operateParams.is_comment ? 0 : 1 })} src={operateParams.is_comment ? selected : unselected} alt="select" />
									</div>
								)}
							</>
						)}

						{/* 入库成功2、入库失败原因 */}
						{visible && visible !== 1 && (
							<>
								<img className="status-img" src={visible === 2 ? success : fail} alt="tip" />
								{visible === 2 ? (
									<div className="status-text">入库成功</div>
								) : (
									<div className="status-text">
										入库失败<span className="status-reason">（{visible}）</span>
									</div>
								)}
							</>
						)}

						{/* 取消、确定、返回按钮 */}
						<MaskFooter cancelText={visible === 1 ? "" : "返回"} onCancel={() => (visible && visible !== 1 ? navigate(-1) : setVisible(0))} onConfirm={visible === 1 && onConfirm} />
					</div>
				</div>
			</Mask>
		</>
	);
}
