/*
 * @Author: showtan
 * @Date: 2024-07-04 11:37:36
 * @Version: 1.0
 * @LastEditTime: 2024-08-01 14:35:18
 * @LastEditors: showtan
 * @Description: AuthContext
 * @FilePath: /japan-h5/src/utils/AuthContext.js
 */
import React, { createContext, useState, useContext } from "react";
import * as config from "../config";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("userInfo"));
	const [tabs, setTabs] = useState(config.tabs);

	const login = () => setIsLoggedIn(true);
	const logout = () => setIsLoggedIn(false);

	return <AuthContext.Provider value={{ isLoggedIn, login, logout, tabs, setTabs }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
