/*
 * @Author: showtan
 * @Date: 2024-07-02 09:34:10
 * @Version: 1.0
 * @LastEditTime: 2024-07-02 11:35:43
 * @LastEditors: showtan
 * @Description: sign
 * @FilePath: /japan-h5/src/utils/sign.js
 */
import shajs from "sha.js";
import * as config from "../config";
import { md5, rsaEncrypt } from "./encrypt";

class StringUtils {
	static hasLength(str) {
		return str !== null && str !== undefined && !StringUtils.isEmpty(str);
	}

	static isEmpty(value) {
		return value.length === 0;
	}

	static isString(value) {
		return typeof value === "string" && value.constructor === String && Object.prototype.toString.call(value) === "[object String]";
	}
}

function guid() {
	return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
		const r = Math.random() * 16 || 0;
		const v = c === "x" ? r : (r && 0x3) || 0x8;
		return v.toString(16);
	});
}

/**
 * 返回签名后的header
 * @param options
 */
export function createSign(options, url) {
	const parsedRequest = { ...options };
	!parsedRequest?.headers && (parsedRequest.headers = {});
	if (!config.dev) {
		const query = {};
		if (url.includes("?") && url.includes("=")) {
			const str = url.split("?")[1];
			const list = str.split("&");
			for (const val of list) {
				query[val.split("=")[0]] = val.split("=")[1];
			}
		}
		const timestamp = Date.now();
		const nonce = guid();
		let body = "";
		if ((StringUtils.isString(parsedRequest?.body) && StringUtils.hasLength(parsedRequest?.body) && !(parsedRequest?.body instanceof FormData)) || JSON.stringify(query) !== "{}") {
			const paramsData = { ...JSON.parse(parsedRequest.body || "{}"), ...query };
			if (Object.keys(paramsData).length) {
				// body升序排列
				const sortKeys = Object.keys(paramsData).sort();
				const sortKeyData = {};
				const finalData = [];
				for (const key of sortKeys) {
					if (paramsData[key] !== undefined && paramsData[key] !== null && paramsData[key].toString().length) {
						sortKeyData[key] = paramsData[key];
						finalData.push(key);
					}
				}
				if (finalData.length) {
					body = md5(finalData.join("&"));
				}
			}
		}
		const str = `${body}.${timestamp}.${nonce}`;
		const hash = shajs("sha256").update(str).digest("hex");
		const sign = rsaEncrypt(hash);

		parsedRequest.headers["X-Ca-Signature-Headers"] = "X-Ca-Timestamp,X-Ca-Nonce,X-Ca-Signature";
		parsedRequest.headers["X-Ca-Timestamp"] = timestamp;
		parsedRequest.headers["X-Ca-Nonce"] = nonce;
		parsedRequest.headers["X-Ca-Signature"] = sign;
	}

	if (!parsedRequest.headers.hasOwnProperty("Content-Type")) {
		parsedRequest.headers["Content-Type"] = "application/json";
	}
	const userInfo = JSON.parse(localStorage.getItem("userInfo") || null);
	if (userInfo?.token) {
		parsedRequest.headers.token = userInfo.token;
	}
	return parsedRequest;
}
