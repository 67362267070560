/*
 * @Author: showtan
 * @Date: 2024-07-03 13:05:18
 * @Version: 1.0
 * @LastEditTime: 2024-08-08 09:26:20
 * @LastEditors: showtan
 * @Description: detail
 * @FilePath: /japan-h5/src/pages/packaging/detail/index.js
 */
import { useState } from "react";
import { Input, Mask, Toast } from "antd-mobile";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "../../../utils/request";
import NavBar from "../../../components/NavBar";
import NumberInfo from "../../../components/NumberInfo";
import DetailFooter from "../../../components/DetailFooter";
import MaskFooter from "../../../components/MaskFooter";
import selected from "../../../assets/selected.png";
import unselected from "../../../assets/unselected.png";
import success from "../../../assets/success.png";
import fail from "../../../assets/fail.png";

/**
 * 合箱待审详情
 * @returns
 */
export default function PackagingDetail() {
	const navigate = useNavigate();
	const params = useParams(); // 参数订单ID
	const detail = JSON.parse(localStorage.getItem("detail") || "{}");
	const data = String(detail.logistical_id) === params.id ? detail : {}; // 详情
	const [visible, setVisible] = useState(0); // 确认合箱1、合箱成功2、合箱失败3、拒绝合箱4、合箱已拒绝5弹窗
	const [operateParams, setOperateParams] = useState({
		weight: "", // 包裹重量
		diff_fee: data.diff_fee && Number(data.diff_fee) ? String(Number(data.diff_fee)) : "", // 补差金额
		diff_reason: data.diff_reason || "", // 补差原因
	});
	const [packaging, setPackaging] = useState(data.children || []); // 包裹
	const [chooseReason, setChooseReason] = useState(""); // 选择拒绝原因
	const [reason, setReason] = useState(""); // 输入拒绝原因
	const list = []; // 增值服务
	data.is_firm && list.push("外包装箱加固");
	data.is_bubble_film && list.push("气泡膜加固");
	const [failMsg, setFailMsg] = useState(""); // 合箱失败原因

	/**
	 * 通过合箱
	 */
	function passHandler() {
		if (!operateParams.weight) {
			Toast.show("请输入合箱后重量~");
			return;
		}
		setVisible(1);
	}

	/**
	 * 确认
	 */
	async function onConfirm() {
		if (visible === 1) {
			// 确认合箱
			const res = await request(
				"order_logistical/mergeBox",
				{
					method: "post",
					body: JSON.stringify({ logistical_id: data.logistical_id, merge_box_status: 3, ...operateParams }),
				},
				true
			);
			if (res?.code === 1) {
				setVisible(2);
			} else {
				setFailMsg(res?.msg || "服务器异常");
				setVisible(3);
			}
		} else {
			// 确认拒绝
			const merge_box_reason = reason || (chooseReason !== "其他原因" ? chooseReason : "");
			if (merge_box_reason) {
				const res = await request("order_logistical/mergeBox", {
					method: "post",
					body: JSON.stringify({ logistical_id: data.logistical_id, merge_box_status: 2, merge_box_reason }),
				});
				res?.code === 1 && setVisible(5);
			} else {
				Toast.show("请输入拒绝原因~");
			}
		}
	}

	/**
	 * 合箱
	 * @param {*} item
	 */
	function chooseHandler(item) {
		item.choose = !item.choose;
		setPackaging([...packaging]);
	}

	return (
		<>
			{/* header */}
			<NavBar title="合箱待审" />

			{/* 内容 */}
			<div
				className="detail-content"
				style={{
					height: `calc(100vh - 7.71rem - ${window.isIphoneX ? "56px" : "20px"})`,
				}}
			>
				{/* 用户ID、订单编号 */}
				<NumberInfo data={data} noHeader />

				{/* 合箱审核 */}
				<div className="detail-cell-label">合箱审核</div>
				<div className="detail-cell detail-cell3">
					{/* 合箱后重量 */}
					<div className="row-center cell-bottom">
						<div className="form-label form-label2">
							合箱后重量（g）<span>*</span>
						</div>
						<Input type="number" className="form-input" placeholder="请输入合箱后重量" value={operateParams.weight} onChange={(weight) => setOperateParams({ ...operateParams, weight })} />
					</div>
					{/* 补差金额、原因 */}
					<div className="row">
						<div className="form-label">
							补差（日元）
							<div>选填</div>
						</div>
						<div className="flex1">
							<Input type="number" className="form-input cell-bottom1" placeholder="请输入补差金额" value={operateParams.diff_fee} onChange={(diff_fee) => setOperateParams({ ...operateParams, diff_fee })} />
							<Input className="form-input" placeholder="请输入补差原因" value={operateParams.diff_reason} onChange={(diff_reason) => setOperateParams({ ...operateParams, diff_reason })} />
						</div>
					</div>
				</div>

				<div className="detail-cell-label">合箱审核</div>
				<div className="detail-cell detail-cell3">
					{/* 增值服务 */}
					<div className="row-center service service-top1">
						增值服务：<span>{list.join("、") || "无"}</span>
					</div>
					{/* 包裹数量 */}
					<div className="detail-text detail-text1">
						子订单包裹数量：<span>{packaging.length}</span>
					</div>
					{/* 选择合箱包裹 */}
					<div className="detail-text detail-text2">包裹编号：</div>
					{packaging.map((item, index) => (
						<div key={index} className="row-center justify-between packaging-cell">
							{item.delivery_identifier}
							<img className="selected-img" onClick={() => chooseHandler(item)} src={item.choose ? selected : unselected} alt="select" />
						</div>
					))}
				</div>
			</div>

			{/* footer */}
			<DetailFooter grayText="拒绝" confirmText="通过合箱" onGray={() => setVisible(4)} onConfirm={passHandler} />

			{/* 确认合箱1、合箱成功2、合箱失败3、拒绝合箱4、合箱已拒绝5弹窗 */}
			<Mask visible={!!visible} onMaskClick={() => setVisible(0)}>
				<div className="row-center justify-center mask-container">
					<div className="mask-content">
						{/* title */}
						{visible === 1 ? <div className="mask-please no-border">确认通过合箱吗？</div> : visible === 4 && <div className="mask-please">确认拒绝吗？</div>}

						{/* 合箱成功2、合箱失败3、合箱已拒绝5 */}
						{(visible === 2 || visible === 3 || visible === 5) && (
							<>
								<img className="status-img" src={visible === 2 ? success : fail} alt="tip" />
								{<div className="status-text">{visible === 2 ? "合箱申请已通过" : visible === 3 ? `合箱申请失败（${failMsg}）` : "合箱申请已拒绝"}</div>}
							</>
						)}

						{/* 拒绝理由 */}
						{visible === 4 && (
							<>
								<div className="row-center reason-cell">
									<img
										className="selected-img selected-img-margin"
										onClick={() => {
											setChooseReason("最常用的拒绝原因");
											setReason("");
										}}
										src={chooseReason === "最常用的拒绝原因" ? selected : unselected}
										alt="select"
									/>
									最常用的拒绝原因
								</div>
								<div className="row-center reason-cell">
									<img className="selected-img selected-img-margin" onClick={() => setChooseReason("其他原因")} src={chooseReason === "其他原因" ? selected : unselected} alt="select" />
									其他原因
								</div>
								<Input
									className="form-input form-input1"
									placeholder="请输入原因"
									value={reason}
									onChange={(val) => {
										val && setChooseReason("其他原因");
										setReason(val);
									}}
								/>
							</>
						)}

						{/* 取消、确定、返回按钮 */}
						<MaskFooter cancelText={visible === 2 || visible === 3 || visible === 5 ? "返回" : ""} onCancel={() => (visible !== 1 ? navigate(-1) : setVisible(0))} onConfirm={(visible === 1 || visible === 4) && onConfirm} />
					</div>
				</div>
			</Mask>
		</>
	);
}
