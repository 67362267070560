/*
 * @Author: showtan
 * @Date: 2024-07-01 14:53:27
 * @Version: 1.0
 * @LastEditTime: 2024-07-09 11:18:00
 * @LastEditors: showtan
 * @Description:
 * @FilePath: /japan-h5/src/index.js
 */
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Toast } from "antd-mobile";
import Router from "./router";
import "./index.css";
import { AuthProvider } from "./utils/AuthContext";

Toast.config({ duration: 3000 });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<AuthProvider>
		<Router />
	</AuthProvider>
);

reportWebVitals();
